































import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";
import Organization from "@/models/organization";
import organizations from "@/resources/organizations";

export default Vue.extend({
  name: "Organizations",
  components: {
    Header,
  },
  metaInfo: {
    title: "Anbieter - Einander Helfen",
    link: [
      { rel: "canonical", href: "https://einander-helfen.org/organizations" },
    ],
  },
  data() {
    return {
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ".split(""),
    };
  },
  computed: {
    organizationsArray(): Organization[] {
      return organizations;
    },
  },
  methods: {
    /**
     * Provides a caseinsensitive sorted list of all organizations of the given letter.
     *
     * @param {string} letter: The letter that the organization's title must begin with. Caseinsensitive.
     * @return {Organization[]}: A sorted array with all organizations that start with the given letter.
     */
    getLetterOrganizations(letter: string): Organization[] {
      // Filter organizations starting with the given letter
      var letterOrganizations = organizations.filter((organization) =>
        organization.title.toUpperCase().startsWith(letter)
      );
      // Sort organizations caseinsensitive
      return letterOrganizations.sort(function (a, b) {
        return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
      });
    },
  },
});
